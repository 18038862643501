<template>
  <div class="routerList" @contextmenu.prevent="() => showSearch = true" @dblclick="toggleAutoScorll">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span>
          <i class="iconfont icon-vector"/>
        </span>
        <div class="d-flex">
          <span class="text mx-2">{{ t('deviceCenter.deviceList') }}</span>
        </div>
      </div>
      <el-table ref="scrollBoard" :data="tableData" height="890" @row-click="onRowClick">
        <el-table-column :label="t('deviceCenter.deviceCode')" width="130">
          <template #default="scope">
            <div class="table-row-part">
              <DeviceIcon class="table-icon"
                          size="petit"
                          :id="scope.row.id"
              />
              {{ scope.row.deviceCode }}
<!--              <div :hidden="!store.getters['cables/isDeviceAlarm'](parseInt(scope.row.id))"-->
<!--                   class="dev-alarm-sign">-->
<!--                {{ store.getters['cables/getDeviceAlarmsById'](parseInt(scope.row.id)).list.length }}-->
<!--              </div>-->
            </div>
          </template>
        </el-table-column>
        <el-table-column :label="t('deviceCenter.ofWhatCable')" min-width="100">
          <template #default="scope">
            <el-link @click="store.commit('cables/setCurrent', scope.row.cableId); router.push('cablecenter')">
              {{ scope.row.cableName }}
            </el-link>
          </template>
        </el-table-column>
        <el-table-column :label="t('deviceCenter.ofWhatBox')" width="100">
          <template #default="scope">
            <div class="table-row-part">
              <BoxIcon class="table-icon"
                       size="petit"
                       :id="scope.row.boxId"
              />
              {{ scope.row.boxName }}
            </div>
          </template>
        </el-table-column>
      </el-table>

    </div>
  </div>
  <el-dialog v-model="showSearch" style="width: 1000px !important;">
    <el-form inline @keydown.enter="search">
      <el-form-item style="width: 330px">
        <el-input style="font-size: 20px" size="large" placeholder="线路、杆塔沟/接地箱或设备编号" v-model.trim="searchInfo" @change="search" />
      </el-form-item>
      <el-form-item>
        <el-button size="large" @click="search">搜索</el-button>
      </el-form-item>
    </el-form>
    <div v-for="i in candidates" :key="i.id" style="height: 40px; margin: 8px 0 8px 0;">
      <el-link style="font-size: 20px" @click="onCandidateClick(i)">{{ i.boxName }} - {{ i.deviceCode }} - {{ i.model.name }}</el-link>
      <p>{{ i.cableName }}</p>
    </div>
  </el-dialog>
</template>

<script setup>
import {useStore} from "vuex"
import {
  ref,
  watch,
  onMounted,
  onUnmounted
} from "vue"
import DeviceIcon from '@/components/pmsIcon/DeviceIcon.vue'
import BoxIcon from '@/components/pmsIcon/BoxIcon.vue'
import {useRouter} from 'vue-router'
import {getCableName} from "@/utils/cableNameGen";
import { useI18n } from 'vue-i18n'
import {ElMessage} from "element-plus";

const { t } = useI18n()

const scrollBoard = ref()

const store = useStore()
const router = useRouter()

const tableData = ref([])
let ids = []
const rowHeights = ref([])

const autoScroll = ref(true)
let timerId
let timerOutId

function onRowClick(e) {
  _setCurrent(e.id)
}

function loadData() {
  if (!store.state.cables.list || store.state.cables.list.length === 0) {
    store.dispatch('cables/request', false)
        .then(() => {
          updateDevices(store.state.cables.list)
          setDefaultCurrent()
        })
  } else {
    updateDevices(store.state.cables.list)
    setDefaultCurrent()
  }
}

function setDefaultCurrent() {
  if (!store.state.cables.currentDevice) {
    let firstDevice = store.getters['cables/firstDevice']
    store.commit('cables/setCurrentDevice', firstDevice)
  }
}

function updateDevices(newCables) {
  let dataArray = []
  for (let cable of newCables) {
    if (cable.gndboxs) {
      for (let box of cable.gndboxs) {
        if (box.subdevs && box.enable) {
          for (let device of box.subdevs) {
            if (!device.enable) {
              continue
            }
            let d = {
              ...device,
              cableName: getCableName(cable),
              cableId: cable.id,
              boxName: box.name,
              boxModel: box.model.name,
            }
            dataArray.push(d)
          }
        }
      }
    }
  }
  tableData.value = dataArray
  ids = tableData.value.map((row) => row.id)
}

watch(() => store.state.cables.actionStatus.all,
    (status) => {
      if (status === 3) {
        updateDevices(store.state.cables.list)
        setDefaultCurrent()
      }
    })

function isAllZero(list) {
  for (let i of list) {
    if (i !== 0) {
      return false
    }
  }
  return true
}

function _setCurrent(id) {
  store.commit('cables/setCurrentDevice', id)
  const selectedRowClass = 'selected-row'
  let index = ids.indexOf(id)
  let tbody = getTableBody(scrollBoard.value.$refs.bodyWrapper.children)
  for (let i = 0; i < tbody.children.length; i++) {
    let row = tbody.children[i]
    row.classList.remove(selectedRowClass)
    if (i === index) {
      row.classList.add(selectedRowClass)
    }
  }
}

function setCurrent(id) {
  _setCurrent(id)
  if (!rowHeights.value || rowHeights.value.length < 1 || isAllZero(rowHeights.value)) {
    getHeights()
  }
  let init = 0
  let index = ids.indexOf(id)
  let offset = rowHeights.value.slice(0, index).reduce((prev, curr) => prev + curr, init)
  scrollBoard.value.setScrollTop(offset)
}

function getTableBody(list) {
  let newList = []
  for (let n of list) {
    if (n.localName === 'tbody') {
      return n
    } else {
      newList = [...newList, ...n.children]
    }
  }
  return getTableBody(newList)
}


function getHeights() {
  let tbody = getTableBody(scrollBoard.value.$refs.bodyWrapper.children)
  rowHeights.value = [...tbody.children].map((tr) => tr.clientHeight)
}

function scrollToNext() {
  let index = ids.indexOf(store.state.cables.currentDevice),
      next = index + 1 >= ids.length ? 0 : index + 1
  setCurrent(ids[next])
}

function setTimer() {
  if (timerId !== undefined) {
    clearInterval(timerId)
  }
  timerId = setInterval(() => {
    scrollToNext()
  }, 20000)
}

watch(autoScroll,
    () => {
      if (autoScroll.value) {
        setTimer()
      } else {
        clearInterval(timerId)
      }
    })

onMounted(() => {
  loadData()
  updateDevices(store.state.cables.list)
  setTimeout(()=> setCurrent(store.state.cables.currentDevice), 1000)
  setTimer()
})

onUnmounted(() => {
  clearInterval(timerId)
})

addEventListener('mousemove', mouseMoveListener)

function mouseMoveListener() {
  autoScroll.value = false
  clearTimeout(timerOutId)
  timerOutId = setTimeout(() => autoScroll.value = true, 40000)
}

// 搜索, 自动滚动开关
let hasListener = true
const showSearch = ref(false)
const searchInfo = ref('')
const candidates = ref([])

document.onkeydown = (e) => {
  if (!e.ctrlKey || !e.shiftKey) {
    return
  }
  if (e.key === 'F') {
    showSearch.value = true
  } else if (e.key === ' ') {
    toggleAutoScorll()
  }
}

function toggleAutoScorll() {
  autoScroll.value = false
  removeEventListener('mousemove', mouseMoveListener)
  if (hasListener) {
    ElMessage('自动滚动已开启')
  } else {
    addEventListener('mousemove', mouseMoveListener)
    ElMessage('自动滚动已关闭')
  }
  hasListener = !hasListener
}

function search() {
  if (!searchInfo.value) {
    return
  }
  let r = []
  for (let device of tableData.value) {
    for (let k of ['cableName', 'boxName', 'deviceCode']) {
      if (device[k].includes(searchInfo.value)) {
        r.push(device)
      }
    }
  }
  candidates.value = r
}

function onCandidateClick(i) {
  showSearch.value = false
  setCurrent(i.id)
}

</script>

<style lang="scss" scoped>
$box-height: 950px;
$box-width: 100%;
.routerList {
  padding: 16px;
  padding-top: 20px;
  height: $box-height;
  width: $box-width;
  border-radius: 5px;

  .bg-color-black {
    height: $box-height - 30px;
    border-radius: 10px;
  }

  .text {
    color: #c3cbde;
  }

  .body-box {
    border-radius: 10px;
    overflow: hidden;

    .dv-scr-board {
      width: 360px;
      height: 800px;
    }
  }
}
</style>

<style>
.dev-alarm-sign {
  position: absolute;
  top: 2px;
  left: -2px;
  z-index: 1;
  width: 20px;
  height: 20px;
  min-width: 20px;
  line-height: 20px;
  border-radius: 50%;
  background-color: firebrick;
  padding: 3px;
  vertical-align: center;
  text-align: center;
  font-size: small;
}

.online {
  color: #33cea0;
  font-weight: bold;
}

.offline {
  color: dimgrey;
}

.el-table .selected-row {
  font-weight: bold;
  --el-table-text-color: black;
  --el-table-tr-bg-color: green;
}

.table-row {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  align-items: center;
}

.table-row-part {
  /*margin-left: 20px;*/
  display: flex;
  flex-flow: row;
  flex-wrap: nowrap;
  align-items: center;
}

.table-icon {
  flex-shrink: 0;
}
</style>
