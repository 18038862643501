<template>
  <el-table :data="tableData" max-height="698px" lazy>
    <el-table-column :label="t('deviceCenter.deviceDataTableTime')" width="160" fixed>
      <template #default="scope">
        {{ formatTimeToStr(scope.row.time * 1000) }}
      </template>
    </el-table-column>
    <el-table-column v-for="col in columns" :key="col.id" :prop="col.id" :width="getColWidth(col.id)"
      :label="store.state.user.lang === 'zh' ? col.name : col.keyword" />
  </el-table>
  <div class="pagination">
    <el-pagination v-model:currentPage="currentPage" :page-size="pageSize" layout="sizes, prev, pager, next"
      :page-sizes="pageSizes" :total="10000" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
  </div>
</template>

<script setup>
import {
  ref,
  watch,
  defineProps,
  onMounted,
  defineExpose
} from "vue"
import { formatTimeToStr } from '@/utils/date.js'
import { useStore } from 'vuex'
import { getValues } from '@/api/values'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const store = useStore()

const props = defineProps(['time'])

const currentPage = ref(1)
const pageSize = ref(20)
const tableData = ref([])
const columns = ref([])
const pageSizes = [20, 50, 100, 200, 400]

const starttime = ref(0)
const endtime = ref(0)

const setTimePeriod = function () {
  if (!props.time) {
    return
  }
  starttime.value = formatTime(props.time[0])
  endtime.value = formatTime(props.time[1])
}

const setData = function (recvdData, unitMap) {
  let cols = []
  for (let col of recvdData.columns) {
    if (['uuid', 'id', 'cpid', 'time'].includes(col)) {
      continue
    }
    if (unitMap?.[col] && unitMap[col].enable) {
      cols.push({ id: col, name: unitMap[col].name, keyword: col })
    }
  }
  columns.value = cols
  if (recvdData.values.length === 0 || recvdData.columns.length === 0) {
    return
  }
  let d = []
  for (let row of recvdData.values) {
    let rowData = {}
    for (let [index, col] of recvdData.columns.entries()) {
      if (row[index] === null) {
        rowData[col] = '-'
        continue
      }
      if (row[index] === 65535) {
        rowData[col] = '-'
        continue
      }
      if (unitMap?.[col]) {
        rowData[col] = row[index] + unitMap[col].unit
      }
      if (col === 'time') {
        rowData[col] = row[index]
      }
    }
    d.push(rowData)
  }
  tableData.value = d
}

const getTableData = function (params) {
  setTimePeriod()
  let id = store.state.cables.currentDevice
  let unitMap = store.state.cables.unitMap[id]
  if (starttime.value !== 0 && endtime.value !== 0) {
    params = { ...params, starttime: starttime.value, endtime: endtime.value }
  }
  getValues({
    id,
    page: currentPage.value,
    pageSize: pageSize.value,
    desc: 'true',
    ...params
  })
    .then((r) => {
      if (r.code === 0) {
        if (r.data && r.data.length > 0 && r.data[0].Series) {
          let data = r.data[0].Series[0]
          setData(data, unitMap)
          return
        }
        tableData.value = []
      }
    })
}


const handleCurrentChange = function (v) {
  currentPage.value = v
  getTableData()
}

const handleSizeChange = function (v) {
  pageSize.value = v
  getTableData()
}

onMounted(() => {
  if (store.state.cables.currentDevice) {
    getTableData()
  }
})

watch(() => store.state.cables.currentDevice,
  () => {
    getTableData()
  }
)

watch(() => props.time,
  () => {
    getTableData()
  })

const formatTime = function (time) {
  return Math.ceil(time / 1000)
}

function getColWidth(id) {
  if (['simIccid'].includes(id)) {
    return 200
  } else {
    return 150
  }
}

defineExpose({ columns, tableData })

</script>

<style>
.pagination {
  margin-top: 10px;
  position: absolute;
  bottom: 7px;
  right: 5px;
}
</style>