<template>
  <div class="block">
    <el-date-picker
        v-model="value"
        type="datetimerange"
        :shortcuts="shortcuts"
        range-separator="—"
        :start-placeholder="t('deviceCenter.timePickerStart')"
        :end-placeholder="t('deviceCenter.timePickerEnd')"
        @change="onCalendarChange"
        :disabled-date="disabledDate"
        value-format="x"
    />
  </div>
</template>

<script setup>
import {
  ref,
  defineEmits,
} from 'vue'

const emit = defineEmits(['timePicked'])

const value = ref('')
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const shortcuts = [
  {
    text: t('deviceCenter.last10min'),
    value: () => {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 / 6)
      return [start, end]
    },
  },
  {
    text: t('deviceCenter.last30min'),
    value: () => {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 / 2)
      return [start, end]
    },
  },
  {
    text: t('deviceCenter.last1h'),
    value: () => {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000)
      return [start, end]
    },
  },
  {
    text: t('deviceCenter.last1d'),
    value: () => {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24)
      return [start, end]
    },
  },
  {
    text: t('deviceCenter.last3d'),
    value: () => {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 3)
      return [start, end]
    },
  },
  {
    text: t('deviceCenter.last7d'),
      value: () => {
        const end = new Date()
        const start = new Date()
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
        return [start, end]
    },
  },
  {
    text: t('deviceCenter.last30d'),
    value: () => {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
      return [start, end]
    },
  },
  {
    text: t('deviceCenter.last90d'),
    value: () => {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
      return [start, end]
    },
  },
  {
    text: t('deviceCenter.last180d'),
    value: () => {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 180)
      return [start, end]
    },
  },
  {
    text: t('deviceCenter.last365d'),
    value: () => {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 365)
      return [start, end]
    },
  },
]

const disabledDate = (time) => {
  return time.getTime() > Date.now()
}

const onCalendarChange = function (e) {
  emit('timePicked', e)
}

</script>
<style scoped>
.block {
  padding: 30px 0;
  text-align: center;
  border-right: solid 1px var(--el-border-color);
  flex: 1;
}

.block:last-child {
  border-right: none;
}
</style>