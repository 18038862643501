<template>
  <Chart :source="source" :dimensions="dimensions" :unitMap="unitMap"/>
</template>

<script setup>
import Chart from './Chart.vue'
import {
  ref,
  watch,
  defineProps,
  onMounted,
  onUnmounted,
} from "vue"
import {useStore} from 'vuex'
import {getValues} from '@/api/values'

const props = defineProps(['time'])

const store = useStore()

const source = ref([])
const dimensions = ref([])
const unitMap = ref({})

const interval = ref(10)
const starttime = ref(0)
const endtime = ref(0)

const timerId = ref()

// 是否需要即时刷新
const isUpToNow = ref(true)

const setData = function () {
  let params = {
    starttime: starttime.value,
    endtime: endtime.value,
    interval: interval.value + 's'
  }
  let id = store.state.cables.currentDevice
  unitMap.value = store.state.cables.unitMap[id]
  getValues({id, ...params})
      .then((r) => {
        if (r.code !== 0) {
          return
        }
        if (r.data && r.data.length > 0 && r.data[0].Series) {
          let data = r.data[0].Series[0]
          removePrefix(data)
          secondToMillisecond(data)
          setChartData(data)
          return
        }
        setChartData({columns: [], values: []})
      })
}

// 去掉 max_
const removePrefix = function (data) {
  for (let i = 0; i < data.columns.length; i++) {
    if (data.columns[i].startsWith('max_')) {
      data.columns[i] = data.columns[i].slice(4)
    }
  }
}

// echarts 的时间轴需要毫秒
const secondToMillisecond = function (data) {
  const timeIndex = data.columns.indexOf('time')
  for (let value of data.values) {
    value[timeIndex] = value[timeIndex] * 1000
  }
}

const setChartData = function (data) {
  dimensions.value = data.columns
  source.value = data.values
}

const formatTime = function (time) {
  return Math.ceil(time / 1000)
}

watch(() => store.state.cables.currentDevice,
    () => {
      main()
    })

const setTimePeriod = function () {
  if (
      !props.time ||
      (props.time[1] - props.time[0]) < 1000 * 60
  ) {
    endtime.value = Math.ceil(Date.now() / 1000)
    starttime.value = endtime.value - 3600 * 24
    isUpToNow.value = true
    return
  }
  if ((Date.now() - props.time[1]) < 1000 * 10) {
    isUpToNow.value = true
  } else {
    isUpToNow.value = false
  }
  starttime.value = formatTime(props.time[0])
  endtime.value = formatTime(props.time[1])
}


watch(() => props.time,
    (value) => {
      main()
    })

const main = function () {
  if (timerId.value) {
    clearInterval(timerId.value)
  }
  setTimePeriod()
  calculateInterval()
  setData()
  if (isUpToNow.value) {
    setTimer()
  }
}

// 根据时间段自动计算间隔
const calculateInterval = function () {
  store.dispatch('cables/request')
  let defaultInterval = store.getters['cables/getDeviceById'](
      store.state.cables.currentDevice).model?.dataFmt?.timeInterval
  let timeLength = endtime.value - starttime.value
  let i = Math.ceil(timeLength / 60 / 24)
  if (defaultInterval && i < defaultInterval) {
    i = defaultInterval
  }
  interval.value = i
}


const setTimer = function () {
  timerId.value = setInterval(() => {
        starttime.value += interval.value
        endtime.value += interval.value
        setData()
      },
      interval.value * 1000)
}

onMounted(() => main())

onUnmounted(() => clearInterval(timerId.value))

</script>
