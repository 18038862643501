<template>
  <div ref="chartRef" class="chart"></div>
</template>

<script setup>
import {init} from 'echarts'
import {
  ref,
  onMounted,
  defineProps,
  watch,
} from 'vue'
import {useStore} from "vuex";
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const store = useStore()

const chartRef = ref()
let chart = undefined

onMounted(() => {
  chart = init(chartRef.value)
  setOption()
})

// source 即 values api 返回的 values
// dimensions 即 values api 返回的 columns
// seriesCfg 是一个数组，其长度决定了绘图的数量（最大为 4）
// 数组成员可以是字符串，此时以这个字符串为前缀寻找 source 数据中符合条件的列，绘制在一张图上
// 数组成员也可以是对象，此时需要填入 name 和 list 两个属性
// name 即这张图的名字，list 中的成员即为要绘在这张图上的列
const props = defineProps(['source', 'dimensions', 'seriesCfg', 'unitMap'])

let options = {}

// 默认只绘制已使能的数据，每个数据一张图
// 如果要定制绘制，请传入 seriesCfg 参数
const getSeriesGroup = function () {
  let r = []
  if (props.seriesCfg) {
    for (let group of props.seriesCfg) {
      if (typeof group === 'string') {
        for (let cfgTag in props.unitMap) {
          if (cfgTag.startsWith(group)) {
            if (props.unitMap[cfgTag].enable) {
              r.push(cfgTag)
            }
          }
        }
      } else {
        let tempGroup = {id: group.id, name: group.name, list: []}
        for (let cfgTag in props.unitMap) {
          if (group.list.includes(cfgTag)) {
            if (props.unitMap[cfgTag].enable) {
              tempGroup.list.push(cfgTag)
            }
          }
        }
        if (tempGroup.list.length > 0) {
          r.push(tempGroup)
        }
      }
    }
  } else {
    for (let cfgTag in props.unitMap) {
      if (props.dimensions.includes(cfgTag) && props.unitMap[cfgTag].enable) {
        let cfgTagH = props.unitMap[cfgTag].name
        let aggregateGraph = false
        let groupName
        if (['A相', 'B相', 'C相', 'N相'].includes(cfgTagH.slice(0, 2))) {
          aggregateGraph = true
          groupName = cfgTagH.slice(2)
        } else if (cfgTagH.slice(0, 1) === '总') {
          aggregateGraph = true
          groupName = cfgTagH.slice(1)
        }
        if (aggregateGraph) {
          for (let item of ['最大值', '最小值', '差值']) {
            if (groupName.endsWith(item)) {
              groupName = groupName.replace(item, '')
            }
          }
          let groupId = cfgTag.slice(0, -1)
          for (let item of ['Min', 'Max', 'Range']) {
            if (cfgTag.endsWith(item)) {
              let _cfgTag = cfgTag.replace(item, '')
              groupId = _cfgTag.slice(0, _cfgTag.length-1)
            }
          }
          let hasGroup = false
          for (let group of r) {
            if (group.name === groupName) {
              group.list.push(cfgTag)
              hasGroup = true
              break
            }
          }
          if (!hasGroup) {
            r.push({
              id: groupId,
              name: groupName,
              list: [cfgTag]
            })
          }
        } else {
          r.push(cfgTag)
        }
      }
    }
  }
  return r
}

const seriesGroup = ref([])

const getGrids = function () {
  let grids = [{}]
  const gridNumber = seriesGroup.value.length
  switch (gridNumber) {
      // case 2:
      //   grids = [
      //     {
      //       bottom: '55%',
      //     },
      //     {
      //       top: '55%',
      //     },
      //   ]
      //   break
    case 3:
      grids = [
        {
          bottom: '66%',
        },
        {
          top: '36%',
          bottom: '36%',
        },
        {
          top: '66%',
        },
      ]
      break
    case 4:
      grids = [
        {
          bottom: '73%',
        },
        {
          top: '29%',
          bottom: '51%',
        },
        {
          bottom: '29%',
          top: '51%',
        },
        {
          top: '73%',
        },
      ]
      break
    case 5:
      grids = [
        {
          bottom: '80%',
        },
        {
          top: '23%',
          bottom: '61%',
        },
        {
          top: '43%',
          bottom: '43%',
        },
        {
          bottom: '23%',
          top: '61%',
        },
        {
          top: '80%',
        },
      ]
  }
  if (gridNumber < 3) {
    grids = [
      {
        bottom: '55%',
      },
      {
        top: '55%',
      },
    ]
  }
  return grids
}

const getAxes = function (type, yAxis = false) {
  let axes = []
  const gridNumber = seriesGroup.value.length
  for (let [i, group] of seriesGroup.value.entries()) {
    let axis = {
      type,
      gridIndex: i,
      axisLabel: {
        color: colorGrey.value,
      }
    }
    let groupName = group.name
    if (store.state.user.lang !== 'zh') {
      groupName = group.id
    }
    if (!groupName) {
      groupName = props.unitMap[group]?.name
      if (store.state.user.lang !== 'zh') {
        groupName = group
      }
    }
    if (yAxis) {
      axis.name = groupName
      axis.nameLocation = 'middle'
      axis.nameTextStyle = {
        color: colorBlack.value,
        padding: 50,
      }
      axis.axisLabel = {
        formatter: (value) => {
          let unit = ''
          for (let u in props.unitMap) {
            if (u.startsWith(group.id) || u.startsWith(group)) {
              unit = props.unitMap[u].unit
            }
          }
          return String(value) + unit
        },
        color: colorGrey.value,
      }
    }
    // x 轴除最后一条外不显示
    // if (!yAxis && i !== gridNumber - 1) {
    //   axis.show = false
    // }
    axes.push(axis)
  }
  return axes
}

const getMarkArea = function (dimensionIndex) {
  let nullGroups = []
  let nullStart = -1
  let nullEnd = -1
  const timeIndex = props.dimensions.indexOf('time')
  for (let [i, data] of props.source.entries()) {
    if (nullStart < 0 && data[dimensionIndex] === null) {
      if (i > 0) {
        nullStart = props.source[i - 1][timeIndex]
      } else {
        nullStart = data[timeIndex]
      }
    } else if (nullStart !== -1 && !(data[dimensionIndex] === null)) {
      nullEnd = data[timeIndex]
      nullGroups.push([{xAxis: nullStart}, {xAxis: nullEnd}])
      nullStart = -1
    }
  }
  // 边界情况：最近一段没有数据时
  if (nullStart > 0) {
    nullGroups.push([{xAxis: nullStart}, {xAxis: props.source.slice(-1)[0][timeIndex]}])
  }
  return {
    itemStyle: {
      color: colorGrey.value
      // color: 'rgba(255, 173, 177, 0.4)'
    },
    data: nullGroups
  }
}

const lineSeriesTemplate = function (dimension, gridIndex) {
  return {
    name: dimension,
    type: 'line',
    encode: {x: 'time', y: dimension},
    xAxisIndex: gridIndex,
    yAxisIndex: gridIndex,
    showSymbol: false,
  }
}

const getSeries = function () {
  let r = []
  for (let [groupIndex, group] of seriesGroup.value.entries()) {
    let firstInGroup = true
    if (typeof group === 'string') {
      for (let [dimensionIndex, dimension] of props.dimensions.entries()) {
        if (dimension.startsWith(group)) {
          let lineSeries = lineSeriesTemplate(dimension, groupIndex)
          // 只给一条 series 设置 mark area
          if (firstInGroup) {
            lineSeries.markArea = getMarkArea(dimensionIndex)
          }
          r.push(lineSeries)
          firstInGroup = false
        }
      }
    } else {
      for (let [dimensionIndex, dimension] of props.dimensions.entries()) {
        if (group.list.includes(dimension)) {
          let lineSeries = lineSeriesTemplate(dimension, groupIndex)
          if (firstInGroup) {
            lineSeries.markArea = getMarkArea(dimensionIndex)
          }
          r.push(lineSeries)
          firstInGroup = false
        }
      }
    }
  }
  return r
}

const getLegendData = function () {
  let legend = {
    data: [],
    textStyle: {
      color: colorBlack.value,
    },
    inactiveColor: colorGrey.value,
    left: 100,
    formatter: (name) => {
      if (store.state.user.lang === 'zh') {
        return props.unitMap[name].name
      } else {
        return name
      }
    }
  }
  for (let [groupIndex, group] of seriesGroup.value.entries()) {
    let groupName = ''
    if (typeof group === 'string') {
      groupName = group
    } else {
      groupName = group.id
    }
    for (let dimension of props.dimensions) {
      if (dimension.startsWith(groupName)) {
        legend.data.push(dimension)
      }
    }
    let separator = ''
    // 图例换行，每两组（两张图）的图例换一次
    if ([1, 3, 5].includes(groupIndex) && legend.data.length > 5) {
      legend.data.push(separator)
    }
  }
  return legend
}

const setOption = function () {
  seriesGroup.value = getSeriesGroup()
  if (!props.source) {
    return
  }
  let options = {
    legend: getLegendData(),
    tooltip: {
      show: true,
      axisPointer: {
        animation: false
      },
      trigger: "axis",
      formatter: (params) => {
        let l = []
        let time = ''
        for (let p of params) {
          time = `<div>${p.axisValueLabel}</div>`
          let name = p.seriesName
          let index = p.dimensionNames.indexOf(p.seriesName)
          let value = p.data[index]
          let innerText = ''
          let unit = ''
          if (props.unitMap[p.seriesName]) {
            name = props.unitMap[p.seriesName].name
            unit = props.unitMap[p.seriesName].unit
          }
          if (store.state.user.lang !== 'zh') {
            name = p.seriesName
          }
          innerText = `${value}${unit}`
          // 排除掉 null 的情况
          if (value === null) {
            innerText = t('deviceCenter.noData')
          }
          l.push(`<p>${p.marker}${name}：${innerText}</p>`)
        }
        return time + l.join('')
      }
    },
    dataset: {
      dimensions: props.dimensions,
      source: props.source,
    },
    xAxis: getAxes('time'),
    yAxis: getAxes('value', true),
    grid: getGrids(),
    series: getSeries(),
  }
  if (chart) {
    chart.setOption(options, {notMerge: true})
  }
}

watch(
    () => props.source,
    () => setOption(),
)

const colorBlack = ref('whitesmoke')
const colorGrey = ref('grey')

watch(
    () => store.state.printable.flag,
    () => {
      colorBlack.value = 'black'
      setOption()
      store.commit('printable/setDataUrl', chart.getDataURL({type: 'png', excludeComponents: ['tooltip']}))
      colorBlack.value = 'whitesmoke'
      setOption()
    }
)

</script>

<style scoped>
.chart {
  width: 1100px;
  height: 730px;
}
</style>