<template>
  <div class="content-box3 ">
    <el-row justify="space-between">
      <el-col :span="5" class="left-box">
        <div class="deviceList">
          <dv-border-box-13>
            <DeviceList />
          </dv-border-box-13>
        </div>
      </el-col>
      <el-col :span="14" class="middle-box">
        <dv-border-box-13 class="deviceInfo">
          <div class="deviceDesc">
            <div class="deviceDescItem deviceDescText deviceDescMain">
              <div class="flex-no-wrap">
                <el-badge :value="device.numAlarms" :hidden="device.numAlarms === 0">
                  <DeviceIcon style="margin-right: 5px" v-if="hasDevice()" size="grand" :id="device.id" />
                </el-badge>
                <h1 :class="titleClass"> {{ device.model ? t('dbDeviceModels.' + [device.model.name]) : device.name }}
                </h1>
              </div>
              <div class="flex-no-wrap">
                <el-link style="font-size: 18px"
                  @click="store.commit('cables/setCurrent', deviceInfo.cableId); router.push('cablecenter')">
                  {{ adaptiveString(deviceInfo.cable, 28) }}
                </el-link>
                <BoxIcon v-if="hasDevice()" :id="deviceInfo.boxId" />
                {{ adaptiveString(deviceInfo.box, 15) }}
              </div>
            </div>
            <div v-if="hasDevice()" class="deviceDescItem deviceDescText-sm">
              <p>
                <el-tag class="tag-modded">{{ t('deviceCenter.updatedAtTag') }}</el-tag>
                {{ updatedAt(device.id) }}
              </p>
              <p>
                <el-tag class="tag-modded">{{ t('deviceCenter.manufacturerTag') }}</el-tag>
                {{ adaptiveString(store.getters["companies/getById"](device.manufacturerId).name, 20) }}
              </p>
              <p>
                <el-tag class="tag-modded">{{ t('deviceCenter.maintainerTag') }}</el-tag>
                {{ adaptiveString(store.getters["companies/getById"](device.maintenanceId).name, 20) }}
              </p>
            </div>
            <div class="deviceDescItem deviceDescText-sm">
              <p>
                <el-tag class="tag-modded">{{ t('deviceCenter.deviceCodeTag') }}</el-tag>
                {{ device.deviceCode }}
              </p>
              <p>
                <el-tag class="tag-modded">{{ t('deviceCenter.userIdTag') }}</el-tag>
                {{ device.userId }}
              </p>
              <p>
                <el-tag class="tag-modded">{{ t('deviceCenter.commentTag') }}</el-tag>
                {{ device.comment }}
              </p>
              <!--              <el-tag class="alarming tag-modded" v-if="device.numAlarms>0">-->
              <!--                {{ device.numAlarms }}条告警-->
              <!--              </el-tag>-->
              <!--              <el-tag class="no-alarm tag-modded" v-else>无告警</el-tag>-->
            </div>
          </div>
        </dv-border-box-13>
        <TimePicker class="timepicker" @timePicked="onTimePicked" />
        <el-button class="exportBtn2" @click="exportTable">{{ t('deviceCenter.exportDeviceTable') }}</el-button>
        <el-button class="exportBtn" @click="exportData">{{ t('deviceCenter.exportDeviceData') }}</el-button>
        <el-tabs class="tabs" type="border-card">
          <el-tab-pane class="tabPane" :label="t('deviceCenter.deviceDataTable')">
            <Table :time="timePicked" />
          </el-tab-pane>
          <el-tab-pane class="tabPane dark-bg" :label="t('deviceCenter.deviceDataChart')">
            <TheChart :time="timePicked" />
          </el-tab-pane>
        </el-tabs>

      </el-col>
      <el-col :span="5" class="right-box">
        <div>
          <dv-border-box-13 :key="borderKey">
            <AlarmList :height="alarmListHeight" />
          </dv-border-box-13>
        </div>
        <div class="image-viewer">
          <dv-border-box-13 v-show="fileList.length > 0" :key="borderKey">
            <ImageViewer :fileList="fileList" />
          </dv-border-box-13>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script setup>
import DeviceList from './DeviceList.vue'
import TheChart from "./TheChart.vue"
import Table from "./Table.vue"
import AlarmList from "./AlarmList"
import TimePicker from './TimePicker'
import {
  ref,
  watch,
  onMounted,
  computed,
} from 'vue'
import { useStore } from 'vuex'
import { useRouter } from "vue-router";
import * as XLSX from 'xlsx'
import { formatTimeToStr, formatTimeDiff } from '@/utils/date.js'
import DeviceIcon from '@/components/pmsIcon/DeviceIcon.vue'
import BoxIcon from '@/components/pmsIcon/BoxIcon.vue'
import { getCableName } from '@/utils/cableNameGen'
import { getImageList } from "@/api/file";
import ImageViewer from '../components/imageViewer.vue'
import { useI18n } from 'vue-i18n'
import { formatDate } from '@/utils/format'
import { getValues } from '@/api/values'

const { t } = useI18n()

const router = useRouter()

// const tabIndex = ref(0)
//
// const onTabChange = function (v) {
//   tabIndex.value = parseInt(v)
// }

const timePicked = ref()
const onTimePicked = function (e) {
  timePicked.value = e
}

const store = useStore()

const device = ref({})
const deviceInfo = ref({})

// 是否使能，数据名，单位
const unitMap = ref()

function hasDevice() {
  if (device.value && Object.keys(device.value).length > 0) {
    return true
  } else {
    return false
  }
}


const setDeviceInfo = function () {
  let d = {}
  d.company = store.getters['companies/getById'](device.value.companyId).name
  let box = store.getters['cables/getDeviceById'](device.value.boxId)
  let cable = store.getters['cables/getById'](box.cableId)
  d.cable = getCableName(cable)
  d.cableId = box.cableId
  d.box = box.name
  d.boxId = box.id
  d.boxModel = box.model.name
  d.maintainer = store.getters['companies/getById'](device.value.maintenanceId).name
  d.manufacturer = store.getters['companies/getById'](device.value.manufacturerId).name

  deviceInfo.value = d
}

const setData = function (id) {
  if (store.state.cables.currentDevice) {
    device.value = store.getters['cables/getDeviceById'](parseInt(id))
    const deviceValue = store.getters['cables/getDeviceValueById'](parseInt(id))
    if (deviceValue.time === '0000-00-00 00:00:00') {
      device.value['mostRecent'] = '-'
    } else {
      device.value['mostRecent'] = formatTimeToStr(deviceValue.time * 1000)
    }
    device.value['online'] = deviceValue.online
    device.value['isAlarming'] = store.getters['cables/isDeviceAlarm'](parseInt(id))
    device.value['numAlarms'] = store.getters['cables/getDeviceAlarmsById'](parseInt(id)).list.length
    setDeviceInfo()
  }
}

onMounted(() => {
  // store.dispatch("models/request");
  store.dispatch("companies/request");
  store.dispatch('cables/request');
  setData(store.state.cables.currentDevice)
  getImage()
  if (store.state.cables.currentDevice) {
    getExportTableData()
  }
})

watch(() => store.state.cables.currentDevice,
  (id) => {
    setData(id)
    getImage()
  })

watch(() => store.state.cables.actionStatus,
  () => {
    setData(store.state.cables.currentDevice)
    getImage()
  })

watch(() => store.state.cables.currentDevice,
  () => {
    getExportTableData()
  }
)
watch(() => timePicked.value,
  () => {
    getExportTableData()
  })

function exportData() {
  let start, end
  if (
    !timePicked.value ||
    (timePicked.value[1] - timePicked.value[0]) < 1000 * 60
  ) {
    end = Math.ceil(Date.now())
    start = end - 1000 * 3600 * 24
  } else {
    [start, end] = timePicked.value
  }
  store.commit('printable/setTime', { start, end })
  store.dispatch('printable/setDevice', device.value.id)
  let route = router.resolve({ path: "/printable" })
  window.open(route.href)
}

// 导出表格
const starttime = ref(0)
const endtime = ref(0)
const tableData = ref([])
const columns = ref([])
const setExportTableData = function (recvdData, unitMap) {
  let cols = []
  cols.push({ id: 'time', name: '时间', keyword: 'time' })
  for (let col of recvdData.columns) {
    if (['uuid', 'id', 'cpid', 'time'].includes(col)) {
      continue
    }
    if (unitMap?.[col] && unitMap[col].enable) {
      cols.push({ id: col, name: unitMap[col].name, keyword: col })
    }
  }
  columns.value = cols
  if (recvdData.values.length === 0 || recvdData.columns.length === 0) {
    return
  }
  let d = []
  for (let row of recvdData.values) {
    let rowData = {}
    for (let [index, col] of recvdData.columns.entries()) {
      if (row[index] === null) {
        rowData[col] = '-'
        continue
      }
      if (row[index] === 65535) {
        rowData[col] = '-'
        continue
      }
      if (unitMap?.[col]) {
        rowData[col] = row[index] + unitMap[col].unit
      }
      if (col === 'time') {
        rowData[col] = row[index]
      }
    }
    d.push(rowData)
  }
  tableData.value = d
}

const formatTime = function (time) {
  return Math.ceil(time / 1000)
}

const setTimePeriod = function () {
  if (!timePicked.value) {
    return
  }
  starttime.value = formatTime(timePicked.value[0])
  endtime.value = formatTime(timePicked.value[1])
}

const getExportTableData = function (params) {
  setTimePeriod()
  let id = store.state.cables.currentDevice
  let unitMap = store.state.cables.unitMap[id]
  if (starttime.value !== 0 && endtime.value !== 0) {
    params = { ...params, starttime: starttime.value, endtime: endtime.value }
  }
  getValues({
    id,
    page: 1,
    pageSize: 10000,
    desc: 'true',
    ...params
  })
    .then((r) => {
      if (r.code === 0) {
        if (r.data && r.data.length > 0 && r.data[0].Series) {
          let data = r.data[0].Series[0]
          setExportTableData(data, unitMap)
          return
        }
        tableData.value = []
      }
    })
}


function getTableRow(data_one) {
  var new_one = [];
  var len = columns.value.length
  //旧key到新key的映射
  let keyMap = Array.from({ length: len }, (_, index) => index);
  let i = 0
  for (var key in data_one) {
    var newKey = keyMap[i];
    if (columns.value.find((col) => col.id == key)) {
      if (i == 0) {
        new_one[newKey] = formatDate(data_one[key] * 1000);
      } else {
        new_one[newKey] = data_one[key];
      }
      i++
    }
  }

  return [
    new_one
  ]
}

function exportTable() {

  const filename = device.value.deviceCode + '监测数据.xlsx',
    sheet_name = 'Sheet1'
  let aoa = [columns.value.map((col) => store.state.user.lang === 'zh' ? col.name : col.keyword)],
    colsCount = columns.value.length


  for (let i = 0; i < tableData.value.length; i++) {
    let row = getTableRow(tableData.value[i])
    if (!row || row.length < 1) {
      continue
    }
    aoa.push(...row)
  }

  // 排序
  aoa.sort((a, b) => {
    return a[colsCount] - b[colsCount]  // 公司id
      || a[colsCount + 1] - b[colsCount + 1]  // 线路id
  })

  // 删掉排序用的字段
  // aoa = aoa.map((el) => {
  //   return el.slice(0, colsCount)
  // })

  // 写文件
  let wb = XLSX.utils.book_new(),
    ws = XLSX.utils.aoa_to_sheet(aoa)
  XLSX.utils.book_append_sheet(wb, ws, sheet_name)
  XLSX.writeFile(wb, filename)
}




// ↑
function updatedAt(deviceId) {
  if (deviceId === undefined) {
    return
  }
  let mostRecent = store.getters['cables/getDeviceValueById'](deviceId).time
  if (mostRecent === '0000-00-00 00:00:00') {
    return t('deviceCenter.noData')
  }
  let timeDiff = formatTimeDiff(Date.now() - mostRecent * 1000)
  if (timeDiff.length > 0) {
    return t('deviceCenter.updatedHowLongAgo', [timeDiff])
  } else {
    return t('deviceCenter.recentlyUpdated')
  }
}

// 设备图片展示相关
const alarmListHeight = ref(950)
const fileList = ref([])


function getImage() {
  let device = store.getters['cables/getDeviceById'](store.state.cables.currentDevice)
  let box = store.getters['cables/getDeviceById'](device.boxId)
  getImageList(box.deviceCode).then((r) => {
    if (r.code === 0) {
      fileList.value = r.data
      if (fileList.value.length > 0) {
        alarmListHeight.value = 530
      } else {
        alarmListHeight.value = 950
      }
      borderKey.value += 1
    }
  })
}

const boxHeight = computed(() => (alarmListHeight.value + 'px'))

const borderKey = ref(0)

function adaptiveString(s, maxLength) {
  if (!s || typeof s !== 'string') {
    return ''
  }
  return s.length > maxLength ? s.slice(0, maxLength - 3) + '...' : s
}

//
const titleClass = ref('')

function getTitleClass() {
  if (store.state.user.lang === 'zh') {
    titleClass.value = ''
  } else {
    titleClass.value = 'title-class-non-chinese'
  }
}

getTitleClass()
</script>


<style>
.timepicker {
  position: absolute;
  border: 0 !important;
  padding: 0 !important;
  z-index: 10;
  right: 400px;
  top: 143px;
}

.exportBtn {
  position: absolute;
  /*border: 0 !important;*/
  /*padding: 0 !important;*/
  z-index: 10;
  right: 820px;
  top: 143px;
}

.exportBtn2 {
  position: absolute;
  /*border: 0 !important;*/
  /*padding: 0 !important;*/
  z-index: 10;
  right: 960px;
  top: 143px;
}

.deviceInfo {
  height: 140px;
}

.deviceDesc {
  justify-content: center;
  display: flex;
  height: 140px;
}

.deviceDescItem {
  flex-grow: 1;
  flex-shrink: 1;
  /*width: 200px;*/
  padding-top: 25px;
  /*padding-left: 25px;*/
}

.deviceDescText {
  font-size: 18px;
  line-height: 30px;
}

.deviceDescText-sm {
  font-size: 16px;
  line-height: 25px;
}

.deviceDescMain {
  padding-left: 25px;
  /*flex-grow: 20;*/
}

.tabs {
  background: none !important;
  margin-left: 5px;
  margin-right: 4px;
}

.tabPane {
  height: 735px;
}

.dark-bg {
  border-radius: 20px;
  background: rgba(20, 20, 20, 0.8);
}

.flex-no-wrap {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.tag-modded {
  margin-bottom: 5px;
  font-size: 16px;
  color: darkgrey;
  height: 30px;
}

.alarming {
  color: whitesmoke;
  background: #D92525;
}

.no-alarm {
  color: whitesmoke;
  background: green;
}

.alarmList {
  --alarm-list-height: v-bind(boxHeight);
  height: var(--alarm-list-height);
}

.image-viewer {
  height: 420px;
}

.title-class-non-chinese {
  font-size: 20px;
  max-width: 300px;
  text-overflow-ellipsis: true;
}
</style>