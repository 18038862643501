import service from '@/utils/request'

export const getValues = ({id, ...params}) => {
  if (!id) {
    id = ''
  }
  return service({
    url: '/api/v1/values/' + id,
    method: 'get',
    params
  })
}